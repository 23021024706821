import { useState } from 'react'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { WarningTwoIcon } from '@chakra-ui/icons'
import { Alert, Box, Button, Center, HStack, Stack, Text } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { ComposableRegisterProps } from 'composable/components/account/pages/account-register/account-register-page'
import { InputField } from 'composable/components/forms/input-field'
import { PasswordField } from 'composable/components/forms/password-field'
import { PASSWORD_MIN_LENGTH } from 'composable/helpers/constants/auth'
import { useFormat } from 'helpers/hooks/useFormat'
import { useForm } from 'react-hook-form'
import Toast from 'react-hot-toast'
import * as yup from 'yup'
import { useAccount } from 'frontastic'
import { AccountFormTypes, AccountPageType } from './account-drawer'
import { TitleSection } from './account-title-section'
import { useComposable } from '../composable-provider'

export interface RegisterFormProps {
  data?: ComposableRegisterProps
  type?: AccountPageType
  setAccountFormToShow?: React.Dispatch<React.SetStateAction<AccountFormTypes>>
}

export const RegisterForm = ({ data, type = AccountPageType.PAGE, setAccountFormToShow }: RegisterFormProps) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState<boolean>(undefined)
  const router = useRouter()
  const { accountDrawer } = useComposable()
  const { register: registerAccount } = useAccount()
  const loginLink = data?.loginLink.type === 'link' ? data?.loginLink.link : '/account/login'

  const handleRegister = async (data: { firstName: string; lastName: string; email: string; password: string }) => {
    setIsLoading(true)
    setIsError(undefined)
    try {
      const response = await registerAccount(data)
      if (!response.accountId) {
        setIsError(true)
        Toast.error("Sorry. We couldn't create your account..")
      } else {
        Toast.success(
          formatMessage({
            id: 'account.register.success.message',
          }),
        )
        router.push(loginLink)
        accountDrawer?.onClose()
      }
    } catch (err) {
      setIsError(true)
      Toast.error(err.message)
    }
    setIsLoading(false)
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    firstName: string
    lastName: string
    email: string
    emailRepeat: string
    password: string
    passwordRepeat: string
  }>({
    resolver: yupResolver(registerFormSchema({ formatMessage })),
    mode: 'all',
  })

  const content = {
    title: formatMessage({ id: 'account.register.title' }),
    description: formatMessage({ id: 'account.register.description' }),
    createAccount: formatMessage({ id: 'action.createAccount' }),
    alreadyAMember: formatMessage({
      id: 'account.register.label.alreadyAMember',
    }),
    input: {
      firstName: {
        label: formatMessage({ id: 'account.register.label.firstName' }),
        placeholder: formatMessage({
          id: 'account.register.label.firstName',
        }),
      },
      lastName: {
        label: formatMessage({ id: 'account.register.label.lastName' }),
        placeholder: formatMessage({
          id: 'account.register.label.lastName',
        }),
      },
      email: {
        label: formatMessage({ id: 'account.register.label.email' }),
        placeholder: formatMessage({
          id: 'account.register.label.emailAddress',
        }),
      },
      emailRepeat: {
        label: formatMessage({ id: 'account.register.label.emailRepeat' }),
        placeholder: formatMessage({
          id: 'account.register.label.emailAddress',
        }),
      },
      password: {
        label: formatMessage({
          id: 'account.register.label.password',
        }),
        placeholder: formatMessage({
          id: 'account.register.label.password.placeholder',
        }),
      },
      passwordRepeat: {
        label: formatMessage({
          id: 'account.register.label.passwordRepeat',
        }),
        placeholder: formatMessage({
          id: 'account.register.label.password.placeholder',
        }),
      },
    },
    button: {
      login: formatMessage({ id: 'action.login' }),
    },
    error: {
      somethingWentWrong: formatMessage({
        id: 'account.register.error.somethingWentWrong',
      }),
    },
  }

  return (
    <Box>
      <TitleSection type={type} title={content.title} description={content.description} />
      {isError && (
        <Alert mt="30px" status="error" borderRadius={'6px'}>
          <WarningTwoIcon width="5" height="5" color="danger.500" mr="3" />
          {content.error.somethingWentWrong}
        </Alert>
      )}
      <Box pt={10}>
        <form
          role={'form'}
          aria-label={content.title}
          onSubmit={handleSubmit((data) => {
            if (isLoading) {
              return
            }
            handleRegister(data)
          })}
        >
          <Stack spacing={4} direction="column">
            <InputField
              label={content.input.firstName.label}
              inputProps={{
                placeholder: content.input.firstName.placeholder,
                ...register('firstName'),
              }}
              error={errors.firstName}
              isRequired
            />
            <InputField
              label={content.input.lastName.label}
              inputProps={{
                placeholder: content.input.lastName.placeholder,
                ...register('lastName'),
              }}
              error={errors.lastName}
              isRequired
            />
            <InputField
              label={content.input.email.label}
              inputProps={{
                placeholder: content.input.email.placeholder,
                ...register('email'),
              }}
              error={errors.email}
              isRequired
            />
            <InputField
              label={content.input.emailRepeat.label}
              inputProps={{
                placeholder: content.input.emailRepeat.placeholder,
                ...register('emailRepeat'),
              }}
              error={errors.emailRepeat}
              isRequired
            />
            <PasswordField
              label={content.input.password.label}
              inputProps={{
                placeholder: content.input.password.placeholder,
                ...register('password'),
              }}
              error={errors.password}
              isRequired
            />
            <PasswordField
              label={content.input.passwordRepeat.label}
              inputProps={{
                placeholder: content.input.passwordRepeat.placeholder,
                ...register('passwordRepeat'),
              }}
              error={errors.passwordRepeat}
              isRequired
            />
          </Stack>

          <Box mt="30px" display="flex" justifyContent="center">
            <Button type="submit" isLoading={isLoading} colorScheme="blue" width={'full'}>
              {content.createAccount}
            </Button>
          </Box>
        </form>

        <Center mt={4}>
          <HStack fontSize="sm" fontWeight="extrabold">
            <Text>{content.alreadyAMember}</Text>
            {type === AccountPageType.PAGE ? (
              <Box as={NextLink} href={loginLink}>
                <Text layerStyle={'link-text'}>{content.button.login}</Text>
              </Box>
            ) : (
              <Button
                color={'text'}
                fontSize="sm"
                fontWeight="extrabold"
                margin={'auto'}
                textDecorationLine={'underline'}
                textUnderlineOffset={4}
                variant={'link'}
                onClick={() => setAccountFormToShow?.('login')}
              >
                {content.button.login}
              </Button>
            )}
          </HStack>
        </Center>
      </Box>
    </Box>
  )
}

const registerFormSchema = (deps: { formatMessage: (params: { id: string }) => string }) => {
  const { formatMessage } = deps
  return yup.object().shape({
    firstName: yup.string().required(formatMessage({ id: 'validation.firstNameRequired' })),

    lastName: yup.string().required(formatMessage({ id: 'validation.lastNameRequired' })),

    email: yup
      .string()
      .email(formatMessage({ id: 'validation.emailValid' }))
      .required(formatMessage({ id: 'validation.emailRequired' })),

    emailRepeat: yup
      .string()
      .required(formatMessage({ id: 'validation.emailRepeat' }))
      .oneOf([yup.ref('email'), null], formatMessage({ id: 'validation.emailRepeatMatches' })),

    password: yup
      .string()
      .required(formatMessage({ id: 'validation.passwordRequired' }))
      .min(PASSWORD_MIN_LENGTH, formatMessage({ id: 'validation.passwordMinLength' }))
      .matches(/[a-z]/, formatMessage({ id: 'validation.passwordLowercase' }))
      .matches(/[A-Z]/, formatMessage({ id: 'validation.passwordUppercase' }))
      .matches(/[0-9]/, formatMessage({ id: 'validation.passwordNumbers' }))
      .matches(
        /^[0-9A-Za-z]*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?][0-9a-zA-Z]*$/,
        formatMessage({ id: 'validation.passwordSpecialCharacter' }),
      ),

    passwordRepeat: yup
      .string()
      .required(formatMessage({ id: 'validation.passwordRepeat' }))
      .oneOf([yup.ref('password'), null], formatMessage({ id: 'validation.passwordRepeatMatches' })),
  })
}
