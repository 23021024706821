import { useState } from 'react'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { WarningTwoIcon } from '@chakra-ui/icons'
import { Alert, Box, Button, HStack, Stack, Text, VStack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { ComposableLoginProps } from 'composable/components/account/pages/account-login/account-login-page'
import { InputField } from 'composable/components/forms/input-field'
import { PasswordField } from 'composable/components/forms/password-field'
import { useFormat } from 'helpers/hooks/useFormat'
import { useForm } from 'react-hook-form'
import { IoLogoFacebook, IoLogoGoogle } from 'react-icons/io5'
import * as yup from 'yup'
import { useAccount } from 'frontastic'
import { AccountFormTypes, AccountPageType } from './account-drawer'
import { TitleSection } from './account-title-section'
import { SectionDivider } from '../section-divider'

export interface LoginFormProps {
  data?: ComposableLoginProps
  type?: AccountPageType
  setAccountFormToShow?: React.Dispatch<React.SetStateAction<AccountFormTypes>>
}

export const LoginForm = ({ data, type = AccountPageType.PAGE, setAccountFormToShow }: LoginFormProps) => {
  const router = useRouter()
  const { formatMessage } = useFormat({ name: 'common' })
  const { login } = useAccount()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const registerLink = data?.registerLink.type === 'link' ? data?.registerLink.link : '/account/register'
  const forgotPasswordLink =
    data?.resetPasswordLink.type === 'link' ? data?.resetPasswordLink.link : '/account/reset-password'

  const handleLogin = async (email: string, password: string) => {
    setIsLoading(true)
    const account = await login(email, password)
    if (account?.accountId && account.confirmed) {
      await router.push('/')
    } else {
      setError(`${account}`)
    }
    setIsLoading(false)
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string; password: string }>({
    resolver: yupResolver(loginFormSchema({ formatMessage })),
    mode: 'all',
  })

  const content = {
    ariaLabel: {
      signIn: formatMessage({ id: 'account.login.title' }),
    },
    title: formatMessage({ id: 'account.login.title' }),
    description: formatMessage({ id: 'account.login.description' }),
    loginWithFacebook: formatMessage({
      id: 'account.login.loginWithFacebook',
    }),
    loginWithGoogle: formatMessage({
      id: 'account.login.loginWithGoogle',
    }),
    notAMemberYet: formatMessage({ id: 'account.login.notAMemberYet' }),
    createAnAccount: formatMessage({
      id: 'account.login.createAnAccount',
    }),
    or: formatMessage({ id: 'text.or' }),
    input: {
      email: {
        label: formatMessage({ id: 'account.register.label.email' }),
        placeholder: formatMessage({ id: 'account.login.label.email' }),
      },
      password: {
        label: formatMessage({ id: 'account.login.label.password' }),
        placeholder: formatMessage({
          id: 'account.register.label.password.placeholder',
        }),
      },
    },
    button: {
      login: formatMessage({ id: 'action.login' }),
      forgotPassword: formatMessage({ id: 'action.forgotPassword' }),
      needToRegister: formatMessage({ id: 'action.needToRegister' }),
    },
    error: {
      incorrectSignIn: formatMessage({
        id: 'account.login.error.incorrectSignIn',
      }),
    },
  }

  const SocialLogin = () => (
    <VStack padding={'32px 0'} spacing={4} alignItems={'stretch'}>
      <Button
        size={{ base: 'md', md: 'lg' }}
        variant={'outline'}
        border={'1px solid'}
        borderColor={'text-muted'}
        color={'text'}
        leftIcon={<IoLogoFacebook />}
        aria-label={content.loginWithFacebook}
      >
        <Text textStyle={{ base: 'heading-mobile-50', md: 'heading-mobile-200' }}>{content.loginWithFacebook}</Text>
      </Button>
      <Button
        size={{ base: 'md', md: 'lg' }}
        variant={'outline'}
        border={'1px solid'}
        borderColor={'text-muted'}
        color={'text'}
        leftIcon={<IoLogoGoogle />}
        aria-label={content.loginWithGoogle}
      >
        <Text textStyle={{ base: 'heading-mobile-50', md: 'heading-mobile-200' }}>{content.loginWithGoogle}</Text>
      </Button>
    </VStack>
  )

  return (
    <Box>
      <TitleSection type={type} title={content.title} description={content.description} />
      {error && (
        <Alert mt="30px" status="error" borderRadius={'6px'}>
          <WarningTwoIcon width="5" height="5" color="danger.500" mr="3" />
          {content.error.incorrectSignIn}
        </Alert>
      )}
      <SocialLogin />
      <SectionDivider text={content.or} />
      <Box py={6}>
        <form
          role={'form'}
          aria-label={content.ariaLabel.signIn}
          onSubmit={handleSubmit(async (data: any) => {
            await handleLogin(data?.email, data?.password)
          })}
        >
          <Stack spacing="20px" direction="column">
            <InputField
              label={content.input.email.label}
              inputProps={{
                placeholder: content.input.email.placeholder,
                ...register('email'),
              }}
              error={errors.email}
            />
            <PasswordField
              label={content.input.password.label}
              inputProps={{
                placeholder: content.input.password.placeholder,
                ...register('password'),
              }}
              error={errors.password}
            />
          </Stack>

          <Box>
            <VStack mt={6} display="flex" justifyContent="stretch" gap={{ base: 4, md: 6 }}>
              <Button
                size={{ base: 'md', md: 'lg' }}
                type="submit"
                isLoading={isLoading}
                colorScheme="blue"
                width={'full'}
              >
                <Text textStyle={'heading-mobile-200'}>{content.button.login}</Text>
              </Button>

              {type === AccountPageType.PAGE ? (
                <HStack justifyContent={'space-between'} width="full">
                  <Box as={NextLink} href={forgotPasswordLink}>
                    <Text layerStyle={'link-text'}>{content.button.forgotPassword}</Text>
                  </Box>
                  <Box as={NextLink} href={registerLink}>
                    <Text layerStyle={'link-text'}>{content.button.needToRegister}</Text>
                  </Box>
                </HStack>
              ) : (
                // type === drawer
                <>
                  <HStack justifyContent={'space-between'} width="full">
                    <Button
                      color={'text'}
                      fontSize="sm"
                      fontWeight="extrabold"
                      margin={'auto'}
                      textDecorationLine={'underline'}
                      textUnderlineOffset={4}
                      variant={'link'}
                      onClick={() => setAccountFormToShow?.('forgot_password')}
                    >
                      {content.button.forgotPassword}
                    </Button>
                  </HStack>
                  <VStack alignItems={'self-start'} width={'full'} paddingTop={10} spacing={6}>
                    <Text textStyle={'heading-desktop-200'}>{content.notAMemberYet}</Text>
                    <Button
                      size={{ base: 'md', md: 'lg' }}
                      variant={'outline'}
                      border={'1px solid'}
                      borderColor={'primary'}
                      color={'primary'}
                      width={'full'}
                      onClick={() => setAccountFormToShow?.('create_account')}
                    >
                      <Text textStyle={'heading-mobile-200'}>{content.createAnAccount}</Text>
                    </Button>
                  </VStack>
                </>
              )}
            </VStack>
          </Box>
        </form>
      </Box>
    </Box>
  )
}

const loginFormSchema = (deps: { formatMessage: (params: { id: string }) => string }) => {
  const { formatMessage } = deps
  return yup.object().shape({
    email: yup
      .string()
      .email(formatMessage({ id: 'validation.emailValid' }))
      .required(formatMessage({ id: 'validation.emailRequired' })),

    password: yup.string().required(formatMessage({ id: 'validation.passwordRequired' })),
  })
}
