import { useState } from 'react'
import { Alert, Button, Box, VStack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { ComposableForgotPasswordProps } from 'composable/components/account/pages/account-forgot/account-forgot-page'
import { InputField } from 'composable/components/forms/input-field'
import { useFormat } from 'helpers/hooks/useFormat'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useAccount } from 'frontastic'
import { AccountPageType } from './account-drawer'
import { TitleSection } from './account-title-section'

export interface ForgotPasswordFormProps {
  data?: ComposableForgotPasswordProps
  type?: AccountPageType
  onCancel?: () => void
}

export const ForgotPasswordForm = ({ data, type = AccountPageType.PAGE, onCancel }: ForgotPasswordFormProps) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const { requestPasswordReset } = useAccount()
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(undefined)

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<{ email: string }>({
    resolver: yupResolver(forgotPasswordFormSchema({ formatMessage })),
    mode: 'all',
  })

  const handleSubmitPasswordReset = async (email: string) => {
    setIsLoading(true)
    setIsSuccess(undefined)
    await requestPasswordReset(email)
    setIsLoading(false)
    setIsSuccess(true)
  }

  const content = {
    title: formatMessage({ id: 'account.forgot.title' }),
    description:
      "Enter the email address associated with your account, and we'll send you a link to set a new password.",
    input: {
      email: {
        label: formatMessage({ id: 'account.forgot.label.email' }),
        placeholder: formatMessage({ id: 'account.login.label.email' }),
      },
    },
    button: {
      submit: formatMessage({ id: 'account.forgot.action.submit' }),
      cancel: formatMessage({ id: 'account.forgot.action.cancel' }),
    },
    alert: {
      success: formatMessage({ id: 'account.forgot.success.message' }),
    },
  }

  return (
    <Box>
      <TitleSection type={type} title={content.title} description={content.description} />
      <Box mt={10}>
        <form
          role={'form'}
          aria-label={content.title}
          onSubmit={handleSubmit(async (data: any) => {
            await handleSubmitPasswordReset(data?.email)
          })}
        >
          {isSuccess && (
            <Alert my={4} status="success" borderRadius={'6px'}>
              {content.alert.success}
            </Alert>
          )}

          <VStack spacing={6} alignItems={'stretch'}>
            <InputField
              label={content.input.email.label}
              inputProps={{
                isReadOnly: isSuccess,
                placeholder: content.input.email.placeholder,
                ...register('email'),
              }}
              error={errors.email}
            />

            <Box mt="30px" display="flex" justifyContent="center">
              <Button
                variant={'solid'}
                type="submit"
                isDisabled={isSuccess || !isValid}
                isLoading={isLoading}
                width={'full'}
                mr={3}
              >
                {content.button.submit}
              </Button>

              <Button
                variant={'solid'}
                type="button"
                isDisabled={isSuccess || isLoading}
                width={'full'}
                ml={3}
                onClick={() => onCancel?.()}
              >
                {content.button.cancel}
              </Button>
            </Box>
          </VStack>
        </form>
      </Box>
    </Box>
  )
}

export const forgotPasswordFormSchema = (deps: { formatMessage: (params: { id: string }) => string }) => {
  const { formatMessage } = deps
  return yup.object().shape({
    email: yup
      .string()
      .email(formatMessage({ id: 'validation.emailValid' }))
      .required(formatMessage({ id: 'validation.emailRequired' })),
  })
}
